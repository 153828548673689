<template>
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700 ml-1">Email</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text"
               :name="fieldName"
               :id="fieldName"
               class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
               :class="{'border-red-300': this.error,'text-red-900': this.error}"
               :placeholder="placeholder ? placeholder : 'Enter your Email Address'"
               v-model="email"
               aria-describedby="email-description"
               @keyup="validateField"
               :disabled="disabled"

        />
        <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <p v-if="error" class="mt-2 text-sm text-red-600" id="email-error">{{this.error}}</p>
    </div>
</template>
<script>
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

var validate = require("validate.js");
var validationConstraints = {
  email: {
    email: true
  }
};
export default {
    components: {
      ExclamationCircleIcon,
    },
    props: ['name','placeholder','value','required','disabled'],
    data() {
      return {
        email: this.value ? this.value : '',
        fieldName: this.name ? this.name : 'email',
        error: ''
      }
    },
    methods: {
      validateField() {
        let validateRes = validate({email: this.email}, validationConstraints);
        if(validateRes && this.email) {
          this.$emit('onValidate',[this.fieldName,validateRes.email[0],this.email]);
          this.error = validateRes.email[0];
        } else {
          this.$emit('onValidate',[this.fieldName,false,this.email]);
          this.error = "";
        }
      },
      onSubmit() {
          if(!this.email && this.required) {
              let emptyError = "Please enter your Email Address.";
              this.$emit('onValidate',[this.fieldName,emptyError,this.email]);
              this.error = emptyError;
          }
      }
    }
}
</script>
