<template>
    <div class=" flex justify-center items-center">
        <div class="loader ease-linear rounded-full" :class="sizeClasses"></div>
    </div>  
</template>
<script>
export default {
   props: ['size','color', 'isDark'],
   computed: {
       _size() {
           return this.size ? this.size : 8;
       },
       sizeClasses() {
           let classes = ['border','border-t'];
           let x4classes = ['h','w'];
           let finalClasses = {};
           finalClasses['border-' + this.color] = true;

           for(let c of classes) {
               finalClasses[c + '-' + this._size] = true;
           }

           for(let c of x4classes) {
               let x4size = parseInt(this._size) * 2;
               finalClasses[c + '-' + x4size.toString()] = true;
           }

           return finalClasses;
       }
   }
}
</script>

<style>
  .loader {
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
    border-top-color: rgba(255, 255, 255, 0.9);
  }
  
  @-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>