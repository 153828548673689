<template>
    <div v-if="sessionToken" class="px-4 py-5 space-y-6 sm:p-12">
        <div v-if="logo">
            <img style="max-width:50px;" :src="logo" />
        </div>
        <h2 v-if="title" class="mt-6 text-xl font-extrabold text-gray-900">
            {{title}}
        </h2>
        <p v-if="description" class="mt-2 text-sm text-gray-600">
            {{description}}
        </p>
        <form method="POST" action="/formSubmit" @submit.prevent="onSubmit">
            <input type="hidden" name="sessionToken" :value="sessionToken" />
            <input type="hidden" name="campaign" :value="campaign" />
          <input type="hidden" name="campaign" :value="campaign" />
            <div>
                <slot>
                    <div v-for="(options,field) in fields" :key="'field-'+fieldKey(field,options)" :class="fieldClasses(field, options)">
                        <component 
                            :required="options.required ? true : false" 
                            :ref="fieldKey(field,options)" 
                            :is="field" 
                            @onValidate="onValidate"  
                            :disabled="processing"
                            />
                    </div>
                </slot>
            </div>
             <div>
                <button v-if="!processing && !formHasErrors" type="submit" class="w-full flex justify-center mt-7 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign Up
                </button>
                
                <button v-if="!processing && formHasErrors" type="button" class="w-full flex justify-center mt-7 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300">
                Sign Up
                </button>

                <button v-if="processing" type="button" disabled class="w-full flex justify-center mt-7 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600">
                    <LoaderSpinner color="indigo-300" size="2"/>
                </button>
            </div>
            <p v-if="footer" class="mt-4 text-xs text-gray-400 text-center">
                {{footer}}
            </p>
        </form>
    </div>
</template>
<script>
import Email from '@/components/fields/Email';
import Firebase from '../fbHelpers.js';
import LoaderSpinner from './LoaderSpinner.vue';

export default {
    components: {
        email: Email,
        LoaderSpinner
    },
    props: ['logo','title','description','campaign', 'fields','footer'],
    async mounted() {
        // issue session token
        const token = await Firebase.callFunction('genFormToken',{});
        this.sessionToken = token;
        this.fieldValues.sessionToken = token;
        this.fieldValues.campaign = this.campaign;
    },
    data() {
        return {
            sessionToken: false,
            fieldErrors: {},
            fieldValues: {},
            submitAttempted: false,
            processing: false
        }
    },
    computed: {
        formHasErrors() {
            return Object.keys(this.fieldErrors).length > 0;
        }
    },
    methods: {
        onValidate(data) {
            this.submitAttempted = false;
            let fieldErrors = {...this.fieldErrors};
            let fieldValues = {...this.fieldValues};

            if(data[1] === false) {
                delete fieldErrors[data[0]];
                fieldValues[data[0]] = data[2];

            } else {
                fieldErrors[data[0]] = data[1];
            }

          this.fieldValues = fieldValues;
          this.fieldErrors = fieldErrors;
        },
        fieldClasses(field,options) {
            let fieldClasses = {};
            fieldClasses['field-type-' + field] = true;
            fieldClasses['field-type-' + this.fieldKey(field,options)] = true;
            return fieldClasses;
        },
        fieldKey(field,options) {
            if(options.name) return options.name;
            else return field;
        },
        async onSubmit() {
            for(let field in this.fields) {
                const fieldKey = this.fieldKey(field, this.fields[field]);
                this.$refs[fieldKey].onSubmit();
            }

            let submitData = this.fieldValues;
            submitData.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if(!this.formHasErrors) {
                this.processing = true;
                const submitResponse = await Firebase.callFunction('internalSubmit',submitData);
                if(this.$router.resolve(submitResponse.redirect)) {
                  this.$router.push({
                    path: submitResponse.redirect,
                    query: {
                      userToken: submitResponse.userToken,
                    }
                  });
                } else {
                  window.location.href = submitResponse.redirect + "?userToken=" + submitResponse.userToken;
                }
            }
        }
    }
}
</script>
