<template>
    
    <core-form 
        logo="https://infusedaddons.com/staging/wp-content/uploads/2016/11/apple-icon-180x180.png"
        title="InfusedAddons Product Updates"
        campaign="inf-product-updates"
        description="Get the latest updates on InfusedWoo, Rocket One-Click and upcoming products from the InfusedAddons Team."
        footer="We will use your email to send you product updates. You can opt-out at any time. We will never share it with any third parties."
        :fields="{
                email: {required: true}
            }"
        />
</template>
<script>
import CoreForm from '../components/CoreForm.vue';

export default {
    components: {
        CoreForm
    }
}
</script>